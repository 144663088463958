import { React, useEffect } from "react";

import "./ContactUsComponent.scss";
import ContuctUsForm from "components/Forms/ContuctUsForm/ContuctUsForm";
import { scrollToTop } from "services/helpers";

const ContactUsComponent = () => {
  useEffect(() => {
    scrollToTop()
  }, [])
  return (
    <>
      <div className="contuctUsComponent">
        <div className="contuctUsComponent_description">
          <h2>Contact Us</h2>
          <p>Please fill out this form to send us an email</p>
        </div>
        <div className="contuctUsComponent_formBlock">
          <ContuctUsForm />
        </div>
      </div>
    </>
  );
};

export default ContactUsComponent;

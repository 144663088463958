import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import HomePage from './pages/HomePage/HomePage';
import CareerAdvice from 'pages/CareerAdvice/CareerAdvice';
import CareerService from 'pages/CareerService/CareerService';
import AboutUsSection from 'pages/AboutUsSection/AboutUsSection';
import TextContent from 'pages/AboutUsSection/_components/TextContent/TextContent';
import FaqComponent from 'pages/AboutUsSection/_components/FaqComponent/FaqComponent';
import InTheNews from 'pages/AboutUsSection/_components/InTheNewsComponent/InTheNewsComponent';
import ContactUsComponent from 'pages/AboutUsSection/_components/ContactUsComponent/ContactUsComponent';
import WebinarsPage from 'pages/WebinarsPage/WebinarsPage';
import CareerArticlePage from 'pages/CareerArticlePage/CareerArticlePage';
import JobsByState from 'pages/JobsByState/JobsByState';
import JobsInState from 'pages/JobsInState/JobsInState';
import Interview3min from 'pages/Interview3min/Interview3min';

function App() {
  return (
    <React.StrictMode>
      <Routes>
        <Route path='/' element={<HomePage/>} />
        <Route path='/career-advice' element={<CareerAdvice/>}/>
        <Route path='/career-advice/:articleTitle' element={<CareerArticlePage/>}/>
        <Route path='/career-service' element={<CareerService/>} />
        <Route path='/webinars' element={<WebinarsPage/>}/>
        <Route path='/pre-interview' element={<Interview3min/>}/>
        <Route path='/jobs-by-state' element={<JobsByState/>}/>
        <Route path='/jobs-for-seniors/:state' element={<JobsInState/>}/>
        <Route path='about' element={<AboutUsSection/>}>
          <Route path='about-us' element={<TextContent/>} />
          <Route path='faq' element={<FaqComponent/>} />
          <Route path='contact-us' element={<ContactUsComponent/>} />
          <Route path='products-and-services' element={<TextContent/>} />
          <Route path='management-team' element={<TextContent/>} />
          <Route path='board-of-directors' element={<TextContent/>} />
          <Route path='advisory-board' element={<TextContent/>} />
          <Route path='in-the-news' element={<InTheNews/>} />
          <Route path='speaking-engagements' element={<TextContent/>} />
          <Route path='testimonials' element={<TextContent/>} />
        </Route>
      </Routes>
    </React.StrictMode>
  );
}

export default App;

/* eslint-disable */
import React, { useRef, useEffect, useState, useMemo } from 'react'
import './AdvicesList.scss'
import CommonListItem from 'components/CommonListItem/CommonListItem'
import AdvicesFilter from '../AdvicesFilter/AdvicesFilter'
import Pagination from 'components/Pagination/Pagination'
import TipWithButton from 'components/TipWithButton/TipWithButton'
import Preloader from 'components/Preloader/Preloader'

import { useGetCareerAdviceArticlesQuery } from 'services/api/careerAdviceApi'
import { scrollToTop, generateURL } from 'services/helpers'

const AdvicesList = () => {
  const [page, setPage] = useState(1)
  const [category, setCategory] = useState('')
  const { data } = useGetCareerAdviceArticlesQuery({category, page})
  const [ mobile, setMobile] = useState(false)

  useEffect(() => {
    scrollToTop()
  },[data])

  const windowWidthRef = useRef(window.innerWidth);

  const resizeFunction = () => {
    if(windowWidthRef.current <= 1080) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }

  const handleResize = () => {
    const newWidth = window.innerWidth;
    windowWidthRef.current = newWidth;
    resizeFunction()
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    resizeFunction()
  }, [windowWidthRef.current]);

  const renderList = (data) => (
    data?.articles.map(item => (
      <div key={item.id}>
        <CommonListItem linked={true} id={item.id} path={`${generateURL(item.name)}`} hover={true} topic={item.category?.name} title={item.name} description={item.description}/>
      </div>
    ))
  )
  const memoizedList = useMemo(() => renderList(data), [data])

  const handleSetCategory = (value) => {
    setCategory(value)
    setPage(1)
  }

  return (
    <section className='advicesList'>
      <div className='advicesList_header'>
        <h2>
          Advice and Resources to Help Your Career
        </h2>
      </div>
      <div className='advicesList_content'>
        {mobile && <TipWithButton 
          title='Looking for Career Services?' 
          description={<p>Career coaches will assist you in finding your perfect position and navigating the application, interview, and job acceptance processes.</p>} 
          url='/career-service'
          button='Learn More'
        />}
        <div className='advicesList_items'>
          {data ? memoizedList : <Preloader size={'L'}/>}
          { data?.total_pages > 1 && 
            <Pagination
              totalPages={data.total_pages}
              currentPage={data.current_page}
              onPageChange={setPage}
            />
          }
        </div>
        <div className='advicesList_filters'>
          <AdvicesFilter setCategory={handleSetCategory}/>
          {!mobile && <TipWithButton 
            title='Looking for Career Services?' 
            description={<p>Career coaches will assist you in finding your perfect position and navigating the application, interview, and job acceptance processes.</p>} 
            url='/career-service'
            button='Learn More'
          />}
        </div>
      </div>
    </section>
  )
}

export default AdvicesList
import { React, useState, useRef } from "react";
import "./ContuctUsForm.scss";


import { validateForm } from "../validations";
import Input from "components/Input/Input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import TextArea from "components/Input/TextArea";
import CommonButton from "components/CommonButton/CommonButton";
import API from 'services/api';
import Popup from 'reactjs-popup';
import SuccessRequestPopup from "components/SuccessRequestPopup/SuccessRequestPopup";
import FormError from "components/FormError/FormError";
import FormPreloader from "assets/images/FormPreloader";

const ContuctUsForm = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    message: "",
  });

  const [requestError, setRequestError] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [firstNameErrors, setFirstNameErrors] = useState("");
  const [lastNameErrors, setLastNameErrors] = useState("");
  const [mailErrors, setMailErrors] = useState("");
  const [messageErrors, setMessageErrors] = useState("");
  const [phoneErrors, setPhoneErrors] = useState("");
  const [onLoad, setOnLoad] = useState(false)
  const formRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e;
    let error;
    if(!name && !value){
      error = validateForm('phone_number', e);
      setFormData((prevData) => ({
        ...prevData,
        'phone_number': e,
      }));
    } else {
      error = validateForm(name, value);
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    
    switch (name) {
      case "first_name":
        setFirstNameErrors(error);
        break;
      case "last_name":
        setLastNameErrors(error);
        break;
      case "email":
        setMailErrors(error);
        break;
      case "message":
        setMessageErrors(error);
        break;
      case undefined:
        setPhoneErrors(error)
        break;

      default:
        break;
    }
  };

  const validateFormData = (formData) => {
    let hasErrors = false;
    const formDataKeys = Object.keys(formData);
    const errors = {};

    formDataKeys.forEach((key) => {
      const error = validateForm(key, formData[key]);
      errors[key] = error;
      if (error) {
        hasErrors = true;
      }
    });

    return { hasErrors, errors };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { hasErrors, errors } = validateFormData(formData);

    setMailErrors(errors.email);
    setFirstNameErrors(errors.first_name);
    setLastNameErrors(errors.last_name);
    setMessageErrors(errors.message);
    setPhoneErrors(errors.phone);

    if (!hasErrors) {
      setOnLoad(true)
      try {
        const response = await API.post('/contact_us', {'member': formData});
        if(response.status === 200) {
          setRequestSuccess(true);
          setOnLoad(false)
          setTimeout(() => {
            setFormData({
              first_name: "",
              last_name: "",
              email: "",
              phone_number: "",
              message: "",
            })
            setRequestSuccess(false);
          }, 3000);
        }
      } catch (error) {
        console.error(error);
        if(window.innerWidth < 780) {
          formRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        setRequestError(true);
        setTimeout(() => {
          setRequestError(false);
        }, 5000);
      }
    }
  };

  return (
    <>
      <div className="contuctUsForm" ref={formRef}>
        {requestError && <FormError />}
        <Popup position='center center' open={requestSuccess}>
          <SuccessRequestPopup
            onClose={() => setRequestSuccess(false)}
            title='Request sent'
            description='Thank you for submitting your request.'
            buttonText='Ok'
          />
        </Popup>
        <form onSubmit={handleSubmit}>
          <div className="fieldsContainer">
            <Input
              label="First Name"
              placeholder="Enter your first name"
              addClass="commonFormInput first_name"
              required={true}
              onChange={handleChange}
              messageText={firstNameErrors}
              error={!!firstNameErrors}
              name="first_name"
              value={formData.first_name}
            />

            <Input
              label="Last Name"
              placeholder="Enter your last name"
              addClass="commonFormInput last_name"
              required={true}
              onChange={handleChange}
              messageText={lastNameErrors}
              error={!!lastNameErrors}
              name="last_name"
              value={formData.last_name}
            />

            <Input
              type="email"
              label="Email Address"
              placeholder="Enter your email"
              addClass="commonFormInput email"
              required={true}
              messageText={mailErrors}
              error={!!mailErrors}
              onChange={handleChange}
              name="email"
              value={formData.email}
            />

            <div className="input-group commonFormInput phone">
              <label>
                Phone number<span>*</span>{" "}
              </label>
              <PhoneInput
                inputProps={{
                  name: "phone_number",
                  required: true,
                }}
                excludeCountries={["ru", "by"]}
                preferredCountries={["us"]}
                country={"us"}
                value={formData.phone}
                onChange={handleChange}
                containerClass={"react-tel-input"}
                inputClass={`custom-phone-input ${
                  phoneErrors && "error"
                }`}
                buttonClass={"custom-phone-button"}
                dropdownClass={"custom-dropdown"}
              />
              {phoneErrors && <p className="error">{phoneErrors}</p>}
            </div>

            <TextArea
              label="Message"
              name="message"
              placeholder="Type message text here"
              addClass="commonFormInput message"
              required={true}
              messageText={messageErrors}
              error={!!messageErrors}
              onChange={handleChange}
              value={formData.message}
            />
          </div>

          <CommonButton
            type="submit"
            text={onLoad ? <FormPreloader/> : "Submit request"}
            addColor="careerServices"
          />
        </form>
      </div>
    </>
  );
};

export default ContuctUsForm;

import React from 'react'
import './TipWithButton.scss'
import { Link } from 'react-router-dom'

const TipWithButton = ({title, description, url, button}) => {

  return (
    <div className='careerServicesLink'>
        <h4 className='careerServicesLink_header'>{title}</h4>      
        {description}
        
        <Link to={url} className='careerServices_link'>
          {button}
        </Link>
    </div>
  )
}

export default TipWithButton
import React from 'react'
import './TopBar.scss'
import MainLogo from 'assets/images/MainLogo'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'

const TopBar = () => {
  return (
    <ContentWrapper>
      <section className='topBar'>
        <div className='topBar_logo'><MainLogo/></div>
        <h2 className='title-orange'>Jobs for People over 50</h2>
      </section>
    </ContentWrapper>
  )
}

export default TopBar
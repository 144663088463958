import { React } from "react";
import { Outlet, useLocation } from "react-router-dom";

import ContentWrapper from "HOC/ContentWrapper/ContentWrapper";
import SectionNavigation from "./_components/SectionNavigation/SectionNavigation";
import TipWithButton from "components/TipWithButton/TipWithButton";

import "./AboutUsSection.scss";
import WrapperSEO from "./_components/WrapperSEO/WrapperSEO";

const AboutUsSection = () => {
  const location = useLocation();
  return (
    <>
      <WrapperSEO location={location.pathname}/>
      <ContentWrapper>
        <div className="aboutUsSection_content">
          <div className="aboutUsSection_itemsBlock">
            <Outlet />
          </div>
          <div className="aboutUsSection_navBlock">
            <SectionNavigation />
            {location.pathname?.split("/")[2] !== "contact-us" && (
              <TipWithButton
                title="Contact Us"
                description={
                  <p>
                    Age-Friendly Institute <br /> 204 2<sup>nd</sup> Avenue <br />{" "}
                    support@agefriendly.org
                  </p>
                }
                url="/about/contact-us"
                button="Contact us"
              />
            )}
          </div>
        </div>
      </ContentWrapper>
    </>
  );
};

export default AboutUsSection;

// jobs and empoyers images
import AFIcon from '../assets/images/new-logo-with-paddings.svg'
import stars from '../assets/images/stars.svg'
import volunteer from '../assets/images/volonteer.svg'
// job seekers images
import img1 from '../assets/images/jobSeekerImg1.webp'
import img2 from '../assets/images/jobSeekerImg2.webp'
import img3 from '../assets/images/jobSeekerImg3.webp'


export const jobsAndEmpoyersData = [
    {
        logo: AFIcon,
        header: 'Find Age Friendly Employers',
        description: 'These employers are committed to being the best places to work for employees at or above age 50.',
        link: 'https://search.retirementjobs.com/jobs?locale=en&page=1&sort=relevance&country=&state=&city=&zip=&latitude=&longitude=&keywords=&city_state_zip=&badge_filter=1&job_id='
    },
    {
        logo: stars,
        header: 'See Reviews',
        description: 'Find out if your employer is age-friendly, unwelcoming to workers age 50+, or somewhere in between. Leave a rating & review to share your experiences.',
        link: 'https://reviews.retirementjobs.com/'
    },
    {
        logo: volunteer,
        header: 'Volunteer',
        description: 'Volunteering is a great way to gain valuable experience and boost your career options.',
        link: 'https://search.retirementjobs.com/jobs?locale=en&page=1&sort=relevance&country=&state=&city=&zip=&latitude=&longitude=&keywords=&city_state_zip=&job_type_filter=Volunteer&job_id='
    }
]

export const jobSeekersData = [
    {
        img: img1,
        header: '3-minute pre interview',
        description: 'Gauge initial interest and qualification for frequently posted positions.',
        link: '/pre-interview'
    },
    {
        img: img2,
        header: 'Webinars',
        description: 'Create account and receive invitations to attend all upcoming webinars.',
        link: '/webinars'
    },
    {
        img: img3,
        header: 'Career services',
        description: 'Find and get the position you want, no matter your career stage.',
        link: '/career-service'
    }
]

export const states = [
    { name: 'Alabama', abbrev: 'AL' },
    { name: 'Alaska', abbrev: 'AK' },
    { name: 'Arizona', abbrev: 'AZ' },
    { name: 'Arkansas', abbrev: 'AR' },
    { name: 'California', abbrev: 'CA' },
    { name: 'Colorado', abbrev: 'CO' },
    { name: 'Connecticut', abbrev: 'CT' },
    { name: 'Delaware', abbrev: 'DE' },
    { name: 'Florida', abbrev: 'FL' },
    { name: 'Georgia', abbrev: 'GA' },
    { name: 'Hawaii', abbrev: 'HI' },
    { name: 'Idaho', abbrev: 'ID' },
    { name: 'Illinois', abbrev: 'IL' },
    { name: 'Indiana', abbrev: 'IN' },
    { name: 'Iowa', abbrev: 'IA' },
    { name: 'Kansas', abbrev: 'KS' },
    { name: 'Kentucky', abbrev: 'KY' },
    { name: 'Louisiana', abbrev: 'LA' },
    { name: 'Maine', abbrev: 'ME' },
    { name: 'Maryland', abbrev: 'MD' },
    { name: 'Massachusetts', abbrev: 'MA' },
    { name: 'Michigan', abbrev: 'MI' },
    { name: 'Minnesota', abbrev: 'MN' },
    { name: 'Mississippi', abbrev: 'MS' },
    { name: 'Missouri', abbrev: 'MO' },
    { name: 'Montana', abbrev: 'MT' },
    { name: 'Nebraska', abbrev: 'NE' },
    { name: 'Nevada', abbrev: 'NV' },
    { name: 'New Hampshire', abbrev: 'NH' },
    { name: 'New Jersey', abbrev: 'NJ' },
    { name: 'New Mexico', abbrev: 'NM' },
    { name: 'New York', abbrev: 'NY' },
    { name: 'North Carolina', abbrev: 'NC' },
    { name: 'North Dakota', abbrev: 'ND' },
    { name: 'Ohio', abbrev: 'OH' },
    { name: 'Oklahoma', abbrev: 'OK' },
    { name: 'Oregon', abbrev: 'OR' },
    { name: 'Pennsylvania', abbrev: 'PA' },
    { name: 'Rhode Island', abbrev: 'RI' },
    { name: 'South Carolina', abbrev: 'SC' },
    { name: 'South Dakota', abbrev: 'SD' },
    { name: 'Tennessee', abbrev: 'TN' },
    { name: 'Texas', abbrev: 'TX' },
    { name: 'Utah', abbrev: 'UT' },
    { name: 'Vermont', abbrev: 'VT' },
    { name: 'Virginia', abbrev: 'VA' },
    { name: 'Washington', abbrev: 'WA' },
    { name: 'West Virginia', abbrev: 'WV' },
    { name: 'Wisconsin', abbrev: 'WI' },
    { name: 'Wyoming', abbrev: 'WY' }
];

export const categories = [
    { name: 'Accounting / Tax Preparers', abbrev: 'AL', link: 'https://search.retirementjobs.com/c-accounting-tax-preparers-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Accounting%2FTax+Preparers&keywords=&city_state_zip=' },
    { name: 'Administrative / Clerical', abbrev: 'CT', link: 'https://search.retirementjobs.com/c-administrative-clerical-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Administrative%2FClerical&keywords=&city_state_zip=' },
    { name: 'Banking', abbrev: 'IL', link: 'https://search.retirementjobs.com/c-banking-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Banking&keywords=&city_state_zip=' },
    { name: 'Business Ownership ', abbrev: 'AK', link: 'https://search.retirementjobs.com/c-business-ownership-opportunities-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Business+Ownership+Opportunities&keywords=&city_state_zip=' },
    { name: 'Caregiving / Healthcare', abbrev: 'DE', link: 'https://search.retirementjobs.com/c-caregiving-healthcare-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Caregiving%2FHealthcare&keywords=&city_state_zip=' },
    { name: 'Driver / Transportation', abbrev: 'AZ', link: 'https://search.retirementjobs.com/c-driver-transportation-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Driver%2FTransportation&keywords=&city_state_zip=' },
    { name: 'Financial', abbrev: 'FL', link: 'https://search.retirementjobs.com/c-financial-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Financial&keywords=&city_state_zip=' },
    { name: 'Human Resources', abbrev: 'IN', link: 'https://search.retirementjobs.com/c-human-resources-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Human+Resources&keywords=&city_state_zip=' },
    { name: 'Insurance', abbrev: 'AR', link: 'https://search.retirementjobs.com/c-insurance-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Insurance&keywords=&city_state_zip=' },
    { name: 'Marketing', abbrev: 'GA', link: 'https://search.retirementjobs.com/c-marketing-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Marketing&keywords=&city_state_zip=' },
    { name: 'Non Profit', abbrev: 'IA', link: 'https://search.retirementjobs.com/c-non-profit-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Non+Profit&keywords=&city_state_zip=' },
    { name: 'Quality Service Inspector', abbrev: 'CA', link: 'https://search.retirementjobs.com/c-quality-service-inspector-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Quality+Service+Inspector&keywords=&city_state_zip=' },
    { name: 'Retail', abbrev: 'HI', link: 'https://search.retirementjobs.com/c-retail-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Retail&keywords=&city_state_zip=' },
    { name: 'Sales', abbrev: 'KS', link: 'https://search.retirementjobs.com/c-sales-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Sales&keywords=&city_state_zip=' },
    { name: 'Security', abbrev: 'CO', link: 'https://search.retirementjobs.com/c-security-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Security&keywords=&city_state_zip=' },
    { name: 'Service Representative', abbrev: 'KY', link: 'https://search.retirementjobs.com/c-customer-service-representative-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Customer+Service+Representative&keywords=&city_state_zip=' },
    { name: 'Volunteer', abbrev: 'ID', link: 'https://search.retirementjobs.com/c-volunteer-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Volunteer&keywords=&city_state_zip=' },
    { name: 'Other', abbrev: 'LA', link: 'https://search.retirementjobs.com/c-other-jobs.html?locale=en&page=1&sort=relevance&country=United+States&state=&city=&zip=&latitude=&longitude=&job_type_filter=Other&keywords=&city_state_zip=' },
];

// categories.sort((a, b) => {
//     const nameA = a.name.toLowerCase();
//     const nameB = b.name.toLowerCase();
//     return nameA.localeCompare(nameB);
// });
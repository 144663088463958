import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/index';
import { HelmetProvider } from 'react-helmet-async';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <Header/>
        <App />
        <Footer/>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>,
);
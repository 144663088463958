import React from 'react'
import './AFResources.scss'
import BorderedSidebar from 'components/BorderedSidebar/BorderedSidebar'
import logo from 'assets/images/AFLogoSmall.svg'
import homeHealth from 'assets/images/AFRecources/Home-health-icon.svg'
import hospital from 'assets/images/AFRecources/Hospital-icon.svg'
import inHomeCare from 'assets/images/AFRecources/In-home-care-icon.svg'
import nurcingHome from 'assets/images/AFRecources/Nurcing-home-icon.svg'
import placesToLive from 'assets/images/AFRecources/Places-to-live-icon.svg'
import seniorLiving from 'assets/images/AFRecources/Senior-living-icon.svg'


const RecourceLink = ({url, logo, title}) => {
  return (
    <a href={url} className='recourceLink' target='_blank' rel="noreferrer">
      <img src={logo} alt='recourceLink-icon'/>
      <p>{title}</p>
    </a>
  )
}

const AFResources = ({ state }) => {
  const generateUrl = (place) => {
    switch(place) {
      case 'places':
        return `https://www.agefriendly.org/search?query=${state.replace(/\s+/g, '+')}&type=state`;
      case 'senior':
          return `https://www.agefriendly.org/communities/${state.replace(/\s+/g, '%20')}`;
      case 'nurcing':
        return `https://www.agefriendly.org/health/search?location=${state.replace(/\s+/g, '%20')}&location_type=state&type=nursing_homes`;
      case 'hospitals':
        return `https://www.agefriendly.org/health/search?query=&location=${state.replace(/\s+/g, '%20')}&location_type=state&type=hospitals`;
      case 'home-health':
        return `https://www.agefriendly.org/health/search?query=&location=${state.replace(/\s+/g, '%20')}&location_type=state&type=home_health_services`;
      case 'inhome-care':
        return `https://www.agefriendly.org/health/search?query=&location=${state.replace(/\s+/g, '%20')}&location_type=state&type=in_home_care`;
      default:
        return ''
    }
  }
  return (
    <div className='afRecources'>
      <BorderedSidebar 
        title={'Find Age-Friendly Resources Near You'}
        subtitle={'Powered by'}
        sublogo={logo}
      >
        <div className='afRecources_list'>
          <RecourceLink title='Places to live' logo={placesToLive} url={generateUrl('places')}/>
          <RecourceLink title='Senior living' logo={seniorLiving} url={generateUrl('senior')}/>
          <RecourceLink title='Nursing homes' logo={nurcingHome} url={generateUrl('nurcing')}/>
          <RecourceLink title='Hospitals' logo={hospital} url={generateUrl('hospitals')}/>
          <RecourceLink title='Home health services' logo={homeHealth} url={generateUrl('home-health')}/>
          <RecourceLink title='In-home care' logo={inHomeCare} url={generateUrl('inhome-care')}/>
        </div>
        
      </BorderedSidebar>
    </div>
  )
}

export default AFResources
/* eslint-disable */
import React from 'react'
import './AgeBiasSection.scss'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import comma from 'assets/images/quote-comma.svg'
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage'

const AgeBiasSection = () => {    
  return (
    <section className='ageBiasSection'>

        <ContentWrapper>
            <div className='ageBiasSection_container'>
                <section className='leftRail'>
                    <div className='ageBiasSection_main'>
                        <h2 className='title-orange'>We'll Help You Beat Age Bias:</h2>
                        <ul className='ageBiasSection_list'>
                            <li className='ageBiasSection_list__item'>
                                <h3>Access:</h3>
                                <p> Certified Age Friendly Employers</p>
                            </li>
                            <li className='ageBiasSection_list__item'>
                                <h3>Advocacy:</h3>
                                <p> Be heard, there’s power in our 1 million + members</p>
                            </li>
                            <li className='ageBiasSection_list__item'>
                                <h3>Advice:</h3>
                                <p> Article, Tips, and One-on-one Support</p>
                            </li>
                        </ul>
                    </div>
                    <div className='ageBiasSection_hiring'>
                        <h2 className='title-orange'>Now Hiring</h2>
                        <div className='ageBiasSection_hiring__list'>
                            <amp-ad width='140' height='140'
                                type="doubleclick"
                                data-slot="/1031329/NH1">
                            </amp-ad>
                            <amp-ad width='140' height='140'
                                type="doubleclick"
                                data-slot="/1031329/NH2">
                            </amp-ad>
                            <amp-ad width='140' height='140'
                                type="doubleclick"
                                data-slot="/1031329/NH2">
                            </amp-ad>
                            <amp-ad width='140' height='140'
                                type="doubleclick"
                                data-slot="/1031329/NH2">
                            </amp-ad>
                        </div>
                    </div>
                </section>
                <aside className='rightRail'>
                    <div className='quote'>
                        <div className='commas'>
                            <LazyLoadImage src={comma} alt='comma'/>
                            <LazyLoadImage src={comma} alt='comma'/>
                        </div>
                        <blockquote>
                            Companies like RetirementJobs.com are filling a void by helping older workers keep their skills and expertise on the job. If these companies continue to adapt to accommodate the aging workforce and we pass the right legislation to help them succeed, we'll keep our economy growing well into the future.
                        </blockquote>
                        <div className='quote_author'>
                            <h4>U.S. Senator Herb Kohl</h4>
                            <span>Retired Chair, Aging Committee</span>
                        </div>
                    </div>
                </aside>
            </div>
        </ContentWrapper>
    </section>
  )
}

export default AgeBiasSection
import React from 'react'
import './Footer.scss'

import LinksList from './_components/LinksList/LinksList'
import FooterBlue from './_components/FooterBlue/FooterBlue'
import { states, categories } from 'constants/textContent'
import { useLocation } from 'react-router-dom'

const Footer = () => {
  const location = useLocation()

  return (
    
    <footer>
        { location.pathname !== '/jobs-by-state' &&
          <div className='footerLinks_wrapper'>
            { 
              !location.pathname.startsWith('/jobs-for-seniors') &&
              <LinksList data={categories} linksType={'category'} title={'Find Jobs by Category'} divider={true}/>
            }
            <LinksList data={states} linksType={'state'} titleLink={'/jobs-by-state'} title={'Browse Jobs by State'} divider={false}/>
          </div>
        }

        <FooterBlue/>
    </footer>
  )
}

export default Footer
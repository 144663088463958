/* eslint-disable */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'constants/api'

export const careerAdviceApi = createApi({
    reducerPath: 'careerAdviceApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL}),
    endpoints: (build) => ({
        getCareerAdviceArticles: build.query({
            query: ({category, page}) => ({
                url: `articles`,
                method: 'GET',
                params: {
                    page: page,
                    category_id: category
                }
            })
        }),
        getCareerAdviceArticlesCategories: build.query({
            query: () => ({
                url: 'categories',
                method: 'GET',
            })
        }),
        getCareerAdviceArticleItem: build.query({
            query: (id) => ({
                url:`articles/${id}`,
                method: 'GET',
            })
        }),
        getArticlesAndNews: build.query({
            query: () => ({
                url: `/right_rail_articles`,
                method: 'GET',
            })
        })
    })
})

export const { 
    useGetCareerAdviceArticlesQuery, 
    useGetCareerAdviceArticlesCategoriesQuery, 
    useGetCareerAdviceArticleItemQuery,
    useGetArticlesAndNewsQuery
} = careerAdviceApi;
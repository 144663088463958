const baseAboutUrl = "/about/";
export const aboutUsSectionNavItems = [
  {
    title: `About Us`,
    link: `${baseAboutUrl}about-us`,
  },
  {
    title: `FAQs`,
    link: `${baseAboutUrl}faq`,
  },
  {
    title: `Contact Us`,
    link: `${baseAboutUrl}contact-us`,
  },
  {
    title: `Products and Services`,
    link: `${baseAboutUrl}products-and-services`,
  },
  {
    title: `Management Team`,
    link: `${baseAboutUrl}management-team`,
  },
  {
    title: `Board of Directors`,
    link: `${baseAboutUrl}board-of-directors`,
  },
  {
    title: `Advisory Board`,
    link: `${baseAboutUrl}advisory-board`,
  },
  {
    title: `In the News`,
    link: `${baseAboutUrl}in-the-news`,
  },
  {
    title: `Speaking Engagements`,
    link: `${baseAboutUrl}speaking-engagements`,
  },
  {
    title: `Testimonials`,
    link: `${baseAboutUrl}testimonials`,
  },
];

export const aboutUsData = {
  title: `About Us`,
  description: [
    `RetirementJobs.com, Inc. now has more than one million members nationwide. It was founded in 2005 by a team of professionals with senior executive backgrounds in media, online recruiting and human capital management. They have worked at major media, recruiting and human capital management brands such as AOL, Monster Worldwide and Towers Watson, built and managed strategic relationships with leading e-recruiters such as CareerBuilder and Monster, and helped build successes such as Salary.com (now owned by IBM).`,
    `Along with a growing number of labor analysts, RetirementJobs.com's human resources experts observe that a long-predicted workforce change is now underway. The change, possibly the largest since women went to work en masse in the 1970's, is spurred by several factors. Among them: population shifts that have baby boomers turning age 65 amidst a dwindling supply of younger workers, health changes that have people living longer than ever, a new definition of retirement that includes a desire to stay active or a need to remain gainfully employed (see "What is a Retirement Job?"), and new research confirming what many may have known all along-- that, on top of experience, older workers bring valuable traits such as lower turnover, greater dependability, a stronger work ethic, and flexibility over work schedule and pay.`,
    `Here at RetirementJobs.com, our goal is to identify companies most-suited to older workers and match them with active, productive, conscientious, mature adults seeking a job or project that matches their lifestyle. Whether you are here seeking to supplement your income, a new challenge, a way to get out and meet people, or another reason, welcome! We are working hard to make each of your visits here a positive one, and we know our work will never quite be done.`,
  ],
  basicData: [
    {
      title: `Free for Job Seekers`,
      description: `The RetirementJobs.com (RJC) service is completely free for job seekers. We provide the option of upgrading to our premium service which gives access to seminars and special content, and enables job seekers to easily identify job openings from employers we have certified or pre-certified as age friendly. RetirementJobs.com also makes money by charging fees to employers who turn to RetirementJobs.com to post open positions and access a database of qualified mature workers. Advertisers pay RetirementJobs.com to deliver messages about topics such as financial services, education, health and travel.`,
    },
    {
      title: `How We Intend to Make a Difference`,
      description: `For every one person who has landed a fulfilling retirement job, there are several others who, despite their valuable work and personality traits, have not mustered the courage or found an easy way to connect with an employer. We aim to ease the way, providing opportunity, inspiration, community and counsel to people over 50 who seek work that matches their lifestyle and economic security needs.`,
    },
    {
      title: `Our Corporate Advisory Board`,
      description: `The RetirementJobs.com Corporate Advisory Board provides ongoing business direction as the Company expands its product offerings. The Advisory Board is comprised of thought leaders from the media, human capital, business and academic communities.`,
    },
  ],
};

export const forJobSeekersList = [
  {
    question: `What does a job seeker’s registered account entail?`,
    answer: (
      <div>
        <p>
          Once created you can tailor your account by adding contact
          information, past work experience, and more to facilitate the job
          application process.
        </p>
        <p>
          You can upload different resumes and cover letters to make applying
          for jobs on site even easier.
        </p>
      </div>
    ),
  },
  {
    question: `How much does a job seeker account cost?`,
    answer: <p>There is no charge to create an account or post your resume.</p>,
  },
  {
    question: `How can upload my resume?`,
    answer: (
      <div>
        <p>
          You can add, modify, or delete your resume by selecting “Resumes &
          Cover Letters” under the menu bar.
        </p>
        <p>You may post more than one resume in your account.</p>
        <p>
          You can hide your resume from employers that have access to the resume
          database by de­selecting the checkbox “make this resume searchable to
          employers” when you are uploading/adding a resume.
        </p>
      </div>
    ),
  },
  {
    question: `Should I use a separate resume for every job?`,
    answer: (
      <p>
        Absolutely. No two job descriptions are the same. We always recommend
        customizing your resume for each and every job you apply to. Take time
        to review the job description carefully and research the company. Make
        sure your resume includes the required skills and technology requested
        in the job description. Also be sure you reference the work processes
        and specific keywords mentioned in the posting.
      </p>
    ),
  },
  {
    question: `How do I edit my contact information?`,
    answer: (
      <p>
        Log in with your current email address and password. Click My Account
        and Edit Profile found under the Job Seeker navigation button.
      </p>
    ),
  },
  {
    question: `How do I change my password or email?`,
    answer: (
      <p>
        Log in with your current email address and password. Click My Account
        and Edit Profile found under the Job Seeker navigation button, then
        select the "edit" link next to your name.
      </p>
    ),
  },
  {
    question: `How do I delete my account?`,
    answer: (
      <p>
        Please contact us to delete your account and all of your information.
      </p>
    ),
  },
  {
    question: `Do I need to be registered to apply for a job?`,
    answer: (
      <p>
        No! Anyone can apply for a job on our site. However, you will not be
        able to store your resume or keep track of the jobs you've applied to
        unless you are registered and logged in at the time you apply for a job.
      </p>
    ),
  },
  {
    question: `What are my search options?`,
    answer: (
      <div>
        <p>
          <b>1. Using the Job Board homepage</b>
        </p>
        <p>
          You can quickly find jobs using broad search criteria. Enter a keyword
          into the first field (keyword) and a city, state, or zip code into the
          second field (location) and click Find a Job. The value you enter into
          the keyword field will search the entire jobs database for those words
          in both the job title and job description.
        </p>
        <p>
          <b>2. Using advanced and filtered search</b>
        </p>
        <p>
          From the homepage, click the Advanced Search link under the Find a Job
          button. If you run a search using the method above, you'll end up on a
          page with job search results from the advanced search options on the
          left hand of the screen.
        </p>
        <p>
          With advanced search, you can search using any of the following
          fields. None of the fields are required and you can get as specific or
          as broad as you'd like:
        </p>
        <p>
          <b>Keywords</b> - this will search the entire jobs database for those
          words in both the job title and job description
        </p>
        <p>
          <b>Categories</b> - select one or more categories you are interested
          in, and jobs posted only into the matching categories will be listed
        </p>
        <p>
          <b>Location</b> - find jobs within a specified location. Enter a city,
          state, zip code, and/or country. The system will automatically find
          jobs within 25 miles of the location you selected unless you choose a
          different search radius using the Zip Code Radius field
        </p>
        <p>
          <b>Position Type</b> - choose the kind of job you are looking for,
          whether it is full or part time
        </p>
        <p>
          <b>Minimum/Maximum Salary </b> - enter a minimum and/or maximum salary
          to find jobs in the range you are looking for. Please note, if a job
          was posted without salary information and you use this search option,
          the job will not appear.
        </p>
        <p>
          <b>Experience Level</b> - select any experience level you are
          interested in. Companies choose the minimum experience level required
          for the position when posting.
        </p>
        <p>
          <b>Education Level</b> - select any education levels you are
          interested in. Companies choose the minimum education level required
          for the position when posting.
        </p>
      </div>
    ),
  },
  {
    question: `Can I sort the job postings by date?`,
    answer: (
      <p>
        Of course! At the top of the job results list you will see the option
        Sort by - with a drop down menu that enables jobs viewing by Date or by
        Salary. Clicking on the word Date will change how the job postings are
        sorted, having the most recent positions listed at the top and older
        ones as you go down the list.
      </p>
    ),
  },
  {
    question: `Can I search the job postings by city?`,
    answer: (
      <div>
        <p>
          <b>Go to the Job Board main page.</b>
        </p>
        <p>
          1. Enter the position you’re interested in or specific keywords that
          describe it.
        </p>
        <p>2. In the second field, enter the preferred location.</p>
        <p>3. Click on Find a Job button to perform the search.</p>
        <p>
          <b>Location search can also be performed through Advanced Search.</b>
        </p>
        <p>1. Click on the Advanced Search link.</p>
        <p>2. Enter the location of your preference.</p>
        <p>
          3. Enter all details specific to your job search, such as: Job
          Categories, Keywords, Salary Range, Experience Level, Education Level,
          etc.
        </p>
        <p>
          4. This will search the entire jobs database based on the location
          given and narrow it by parameters you have specified in the Advanced
          Search fields.
        </p>
      </div>
    ),
  },
  {
    question: `What are saved jobs?`,
    answer: (
      <p>
        You may choose to save a list of jobs to review at a later date. Saved
        jobs can be found in the "Saved Jobs" area on your Account page. Your
        saved jobs will be remembered until you choose to delete them. Note: If
        a job expires and is removed from our system, you will not be able to
        recover the saved job.
      </p>
    ),
  },
  {
    question: `How can I stay current on new jobs that match my resume?`,
    answer: (
      <p>
        Sign up for job alerts to have a list of new postings automatically
        emailed to you. You can create and edit your alerts at any time to help
        customize the types of jobs you are receiving. To create or modify, log
        into your account and select “Job Alerts” from the main menu.
      </p>
    ),
  },
  {
    question: `How can I stop a Job Alert?`,
    answer: (
      <div>
        <p>
          <b>
            Manage your subscription to job alerts anytime by using the
            following steps:
          </b>
        </p>
        <p>Login to your profile</p>
        <p>From the main dashboard, click on Job alerts link</p>
        <p>Select the Delete tab below the job alert you wish to delete.</p>
        <p>
          <b>To cancel email job alerts:</b>
        </p>
        <p>Open an email alert you received</p>
        <p>Scroll to the bottom of the email</p>
        <p>Click the unsubscribe link</p>
        <p>
          Every job alert email has a link at the bottom of the email that says
          unsubscribe. Click the link to cancel the alert. If you have multiple
          alerts set up, you must cancel each one individually.
        </p>
      </div>
    ),
  },
];

export const forEmployersList = [
  {
    question: `Where do I go to buy a job?`,
    answer: (
      <p>
        Click Pricing or Post a Job from the main menu to bring you to the
        different job posting packages we offer.
      </p>
    ),
  },
  {
    question: `What type of payments do you accept?`,
    answer: (
      <div>
        <p>The default payment method is by credit card.</p>
        <p>
          If you need to be invoiced, please contact our sales representative.
        </p>
      </div>
    ),
  },
  {
    question: `I can’t find my receipt!`,
    answer: (
      <p>
        A receipt will be emailed to the email address associated with your
        employer account. It will also be listed in your billing history.
      </p>
    ),
  },
  {
    question: `How do I apply my association member discount to job postings/packages?`,
    answer: (
      <div>
        <p>
          Please contact your association for any discounts/ codes that may be
          available.
        </p>
        <p>
          Member discounts cannot be combined with any other discount nor be
          applied retroactively.
        </p>
      </div>
    ),
  },
  {
    question: `Where can I find statistics and data on site performance?`,
    answer: (
      <p>
        From your dashboard, please select "Manage Existing Jobs". From here you
        will be able to see job statistics such as views and applicants.
      </p>
    ),
  },
  {
    question: `How do I edit/change my contact information and/or password?`,
    answer: (
      <div>
        <p>Log in with your current email address and password.</p>
        <p>
          Click the Preferences under the greeting in the right-hand corner.
        </p>
      </div>
    ),
  },
  {
    question: `How do I delete my account?`,
    answer: (
      <p>
        From your dashboard, please select "Contact Us". From here you can
        request to have your account deactivated.
      </p>
    ),
  },
  {
    question: `How do I post a job?`,
    answer: (
      <div>
        <p>
          You can post a job by going to the Post a Job button in the right or
          on the dashboard. Here you can choose the job posting package you’re
          interested in. You’ll then be brought to a checkout page where you
          will enter your payment information. You will then be prompted to post
          your job!
        </p>
        <p>
          <b>Apply Methods:</b> You can select one of three ways job seekers are
          to apply.
        </p>
        <ul>
          <li>
            Use your account on the Career Center to manage your applications
          </li>
          <li>
            Applicants and their resumes, and cover letters emailed to a
            specific address
          </li>
          <li>Enter a URL where job seekers will be redirected</li>
        </ul>
        <p>
          <b>Note:</b> Applications of job seekers who apply by email are stored
          in your account. We cannot store the candidate’s information if we
          redirect them away from the Career Center as they have just clicked
          through from our site to yours.
        </p>
      </div>
    ),
  },
  {
    question: `How do I get help posting my job?`,
    answer: <p>Please select "Contact Us" from your dashboard.</p>,
  },
  {
    question: `I've posted my job, but I can't find it in the job search results!`,
    answer: (
      <p>
        It can take up to 10 minutes for your newly posted job to appear in the
        search results on the Career Center.
      </p>
    ),
  },
  {
    question: `Can I edit a job I've posted?`,
    answer: (
      <div>
        <p>
          Access your job list by clicking the Manage Existing Jobs on your
          dashboard or in the menu bar. Select Edit from the list of action
          dropdowns to make the desired changes.
        </p>
        <p>
          <b>Note:</b> It can take up to 10 minutes for your changes to appear
          on that job when viewed in the search results.
        </p>
      </div>
    ),
  },
  {
    question: `How can I attract more applicants to my job posting?`,
    answer: (
      <p>
        From your dashboard please select "Contact Us". We are here to help!
      </p>
    ),
  },
  {
    question: `Where can I view a list of jobs that I have posted?`,
    answer: (
      <div>
        <p>
          You can manage jobs you have posted by clicking the link "Manage
          Existing Jobs" on your dashboard or in the menu bar.
        </p>
        <p>
          Please notice the drop down that defaults to Active. You can organize
          your postings by Active, Expired, or Suspended.
        </p>
        <p>
          <b>Note:</b> Only active jobs are returned in your job list. If you
          would like to view your expired or suspended jobs, you must first
          click that option in the available filter.
        </p>
      </div>
    ),
  },
  {
    question: `How can I renew my job?`,
    answer: (
      <div>
        <p>
          To renew your still-active job before it expires (and get a
          discount!), follow these steps:
        </p>
        <p>-Log into your account</p>
        <p>-Click on "Manage Jobs/Your Jobs" at the top</p>
        <p>-Next to your Job's title, click on "Boost"</p>
        <p>-Select the "Additional 30-days" upgrade</p>
        <p>-Check out</p>
      </div>
    ),
  },
  {
    question: `My job has been filled. How can I delete it?`,
    answer: (
      <div>
        <p>Jobs cannot be deleted.</p>
        <p>
          To remove a job from the active list, select Suspend from the action's
          dropdown on the Manage Existing Jobs page.t
        </p>
      </div>
    ),
  },
];

export const productsAndServicesData = {
  title: `Products and Services`,
  description: [
    `RetirementJobs.com is the leading career site for people age 50+, with more than 1 million members. Our experienced and ‘blue chip’ team manages a trusted brand and uses world class technology in order to provide the following range products and services—all focused around communicating with people age 50+. Whether you are a job seeker, employer, publisher, advocacy group, or an organization seeking to target its message to the millions online who are age fifty and over, we invite you to learn more about what we do. We work extra hard to make our customers pleased with our performance.`,
  ],
  expandedData: [
    {
      sectionTitle: `For Employers`,
      sectionData: [
        {
          title: `Recruiting Services `,
          description: `When employers choose to target job candidates age 50+, they turn to RetirementJobs.com for its reputation, brand awareness, expertise and customer service. Nowhere else on the Web can you easily tap into a larger group of people in this important cohort and demographic. We provide Job Posting and Employment Branding solutions You can purchase these services online or ask our friendly and experienced account executives to tailor a solution to your needs.`,
          link: `/about/contact-us`,
          same: true,
        },
        {
          title: `Certified Age Friendly Employer™ Program`,
          description: `The Age-Friendly Institute identifies employers that maintain policies, practices and programs consistent with employment of people age 50 and older based solely on their proficiency, qualifications and contribution. The process and its usefulness to employers and candidates alike are comparable to those of “Consumer Reports”. Employers earning the valuable designation benefit in a variety of ways, including enhancement to their employment brand and visibly higher application rates. The program has won key endorsements from the U.S. Senate Special Committee on Aging and leading American advocacy groups for people age 50+, including AARP. Please visit this page for more information on how to become certified. Plus, check out our additional information on the benefits to you. Please email the Age-Friendly Institute today at info@agefriendly.org.`,
          link: `https://institute.agefriendly.org/initiatives/certified-age-friendly-employer-program/`,
        },
        {
          title: `Consulting and Research`,
          description: `RetirementJobs.com has accumulated a wealth of knowledge and expertise regarding the effective and profitable retention and recruitment of this rapidly growing segment of the workforce. Our targeted experience in general and human resource management of the age 50+ workforce represents a valuable consultative resource for employers. We deliver solutions that draw from our proprietary data and the body of knowledge derived from our experience in human resources consulting and academia. Visit our Research and Consulting section for more details or email us today at service@retirementjobs.com.`,
        },
      ],
    },
    {
      sectionTitle: `For Advertisers Reaching Consumers Age 50+`,
      sectionData: [
        {
          title: `Targeted Consumer Advertising`,
          description: `People visiting our site are being empowered to find jobs with companies that want them. They are seeking new lifestyles and a way to satisfy their economic security needs through our trusted brand. We work hard to earn their trust. You benefit by delivering a message to high volumes of people in your targeted demographic, via e-mail, banner advertisements, site sponsorships and seminars. We deliver programs that are customized to your needs. To learn more, simply email us today at `,
          linkEmail: `sales@search.retirementjobs.com`,
        },
        {
          title: `AgeFriendly.org`,
          description: `The Age-Friendly Institute's AgeFriendly.org promotes the services of trustworthy organizations committed to serving the unique needs of individuals over age 50. The business's products or services have been verified by the staff at AgeFriendly.org.`,
          link: `https://www.agefriendly.org/`,
        },
        {
          title: `Syndication and Licensed Content`,
          description: `The mission of our syndication business is to provide our unique database of jobs from age friendly certified companies that will enhance the breadth and depth of our partners’ retirement-oriented content and service offerings. Engaging with us for this service provides dynamic, daily-refreshed content for your site, giving people a reason to return; generates incremental pages per visitor, helping you deliver additional promotional opportunities for your service; keeps visitors in your site’s look & feel; and makes your site a one-stop-shop for aging planning, ultimately improving the lives of people over 50.`,
        },
      ],
    },
    {
      sectionTitle: `For Job Seekers Age 50+`,
      sectionData: [
        {
          title: `Job Searching`,
          description: `Visitors may search for jobs across the United States and soon, in other countries around the world. We enable people to search based on their ZIP code, key words, and categories relevant to their search. Job seekers may also apply to jobs through our system. These activities are completely free of charge.`,
          link: `https://search.retirementjobs.com/jobs?locale=en&page=1&sort=relevance&country=&state=&city=&zip=&latitude=&longitude=&keywords=&city_state_zip=`,
        },
        {
          title: `Resume Posting`,
          description: `Job seekers may post their resume free of charge in our resume database. When they do, it enables employers to quickly find them and potentially contact the job seeker for a job interview. Employers enjoy using our resume database service to immediately pinpoint candidates with the attributes they’re looking for.`,
          link: `https://search.retirementjobs.com/index.php?page=register_seeker`,
        },
        {
          title: `Career Advice`,
          description: `There are special considerations when it comes to job hunting at this stage of a person’s career. RetirementJobs.com’s experts and editors focus exclusively on these issues and they create a supportive environment. Browse through our retirement stories and you’ll be able to relate, we hope, to people like you who have made the turn from a mainline career to retirement job. Comb the advice articles for practical “how-to” pieces on what it takes to conduct a job search these days, and what companies are looking for in workers over 50. The information we provide through our own published articles and audio recordings that you can play through your computer are also free of charge.`,
          link: `/career-advice`,
          same: true,
        },
        {
          title: `Career Services`,
          description: `We offer personalized career advice and resume writing services. Please email sales@retirementjobs.com for our latest rates and offerings.`,
          link: `/career-service`,
          same: true,
        },
      ],
    },
  ],
};

export const managementTeamData = {
  title: `Management Team`,
  description: [
    `The RetirementJobs.com leadership team is comprised of professionals with executive backgrounds in media, e-recruiting, human resources & online consumer marketing.`,
  ],
  basicData: [
    {
      title: `Tim Driver, CEO`,
      description: `RetirementJobs.com was founded by entrepreneur Tim Driver, who has a successful track record starting and building media and service businesses. He is the CEO of Age Friendly Ventures, Inc. and also leads the Age-Friendly Institute. Prior to Age Friendly Ventures, as the SVP of Consumer Products, Driver headed and helped popularize the Salary.com division that leveled the playing field for employees by making compensation and career information available to millions of individuals. He also served on the firm's Board of Directors. Prior to Salary.com, Driver was a group director in Personal Finance and Strategic Businesses units at AOL. He built consumer category offerings, including the AOL Careers Channel, that became leading web destinations. Driver writes career advice articles and has appeared in numerous national media outlets including the Wall Street Journal, MSNBC and CNN. Driver's professional experience also includes management consulting with Accenture and producing programs for CNN. He is a Certified Compensation Professional.`,
    },
    {
      title: `Karen Prendergast, Database Operations`,
      description: `Karen Prendergast has spent the last decade focused on database operations with a focus on sales and marketing operations and product management. She has worked for numerous public and private corporations; including Salary.com and Intronis (now Barracuda MSP) as well as operating as an independent Salesforce Consultant. Her areas of expertise include: Data and Database Management, Operational and Financial Forecasting, Business Process Development and Implementation. She is a Salesforce Certified Advanced Administration and certified Salesforce Sales and Service Cloud Consultant. Karen has a bachelor's degree from Salem State University.`,
    },
    {
      title: `Emily Johnson, Digital Marketing`,
      description: `Emily Johnson has worked in the digital marketing and advertising space for 5+ years with a specific specialization and emphasis in social media and email marketing. Primarily working for non-profit organizations, Emily executes cost-effective digital marketing strategies that engage the target audience while maximizing the return on investment. Emily has a BS in Biology and Certifications in Digital Marketing and Design from the University of New Hampshire. In her free time, Emily loves to ski, run, and go bird watching.`,
    },
    {
      title: `Hilary Hanson Bruel, Art Direction`,
      description: `Hilary Hanson Bruel is an art director with over 25 years of graphic design experience. Since 2006, she has provided creative direction and design for digital media properties such as RetirementJobs.com and Mature Caregivers. Prior, she was Art Director for Putnam Investments and Creative Director for Hearst Magazines. Hilary is an active supporter for non-profit organizations focused on education, and is also a fine artist whose paintings are exhibited in the Boston area. She has a Bachelor's degree in Visual and Environmental Studies from Harvard University.`,
    },
    {
      title: `Donna Richey, Member Services`,
      description: `Donna Richey has experience as a team leader and trainer in the fast-paced and competitive high-tech Corporate Sales environment as well as a dedicated customer service professional. Her past experiences have been with Monster and PC Connection, where she coached Account Managers to optimize territory growth by improving closing rates. She’s active in the Arts and has former memberships in the NH Artist Association, the National Watercolor Society and the Chelmsford Artist Association. She enjoys teaching and is an avid Sea glass hunter. Donna has a Bachelor's degree in Business from New Haven Hartford Business School.`,
    },
  ],
};

export const boardOfDirectorsData = {
  title: `Board of Directors`,
  description: [],
  basicData: [
    {
      title: `Eric Beyer, President at Privia Health- Mid Atlantic`,
      description: `Eric Beyer, throughout his career as a healthcare executive, has been known for his ability to effectively build and lead healthcare organizations and for aligning the goals of hospitals and physicians. Mr. Beyer was instrumental in building the Partners Healthcare Network of community providers, a network of over 6,000 physicians caring for nearly one million patients with more than a third in capitated contracts. Eric went on to become the CEO of The Tufts Medical Center Physician Organization and later the CEO of Tufts Medical Center and the Floating Hospital for Children. As the Principal of APEX, Eric now leads projects including physician network development, medical group turnarounds, represents providers in M&A transactions, and consults with healthcare services firms and investors.`,
    },
    {
      title: `Bill Coleman, Chief Data Officer at Payscale`,
      description: `Prior to Payscale, Mr. Coleman was the SVP of Compensation at Salary.com. He oversaw that organization’s data operations, products, and research related to both broad-based and executive compensation. Mr. Coleman helped build Salary.com from a startup to a $50 million publicly traded company. Mr. Coleman was also Salary.com’s SVP of Corporate Development, where he acquired six companies. Before Salary.com, Mr. Coleman held senior consulting and management positions with Watson Wyatt Worldwide and PricewaterhouseCoopers. He is a frequent commentator on topics of retirement planning and human resource issues in outlets including: NBC Nightly News, The Today Show, The Wall Street Journal, The New York Times, Forbes, Fortune, and Money Magazine. Mr. Coleman has a bachelor’s of science degree in mathematics from the Massachusetts Institute of Technology (M.I.T.).`,
    },
    {
      title: `Tim Driver, CEO and Founder, Age Friendly Ventures, Inc.`,
      description: `Tim Driver has a lengthy background of managing successful Internet businesses. Prior to launching RetirementJobs.com, he served as Senior Vice President of Consumer Products at Salary.com, where he headed and helped popularize the division that makes compensation and career information available to millions of individuals, working with leading Internet e-recruiting brands. He also served on the firm's Board of Directors. Prior to Salary.com, Driver was a group director in Programming and Strategic Businesses units at AOL. He built consumer category offerings, including the AOL Careers Channel, that became leading destinations on the web. Driver's professional experience also includes management consulting with Accenture Consulting and producing live news programs for CNN. He is a Certified Compensation Professional.`,
    },
    {
      title: `Eric Schnadig, Growth Executive, Investor and Advisor`,
      description: `Eric is an executive, entrepreneur and investor with extensive experience building, leading, and advising public and private companies in high growth technology markets. As an investor, Eric has played key roles in raising $150M+ in funding for early and growth stage companies and in creating $3B+ in liquidity to investors and shareholders via multiple successful exits. Most recently, Eric was the CEO of Tervela, the maker of Cloud FastPath, a leading SaaS that automates movement and syncing of content to cloud platforms like GDrive, Teams, Box, and Dropbox. Prior to Tervela, Eric was the SVP of WW Sales at Unica, a leading marketing software platform that was acquired by IBM in 2010.`,
    },
  ],
};

export const advisoryBoardData = {
  title: `Advisory Board`,
  description: [
    `The RetirementJobs.com Corporate Advisory Board provides ongoing business direction as the Company expands its product offerings. The Advisory Board is comprised of thought leaders from the business and academic communities who believe in the vision of the Company and who have a desire to make a difference regarding workforce issues. It serves as a vehicle for communication and interaction between the Company and its key constituencies such as members of industry, researchers focused on the aging workforce, clients and the business community at large. In addition to being ambassadors for the Company, the board advises management on strategy, content, research, consulting and other projects.`,
  ],
  basicData: [
    {
      title: `Doug Furbush`,
      description: `Doug is the former Area President for Gallagher Affinity. Gallagher is the third largest Insurance agency in the world which is publicly traded on the New York Stock Exchange. Doug brings a wealth of sales and management experience in the affinity marketing space with over 40 years success. Doug has provided member benefits to hundreds of associations and affinity groups with services marketed in the Insurance, financial, travel and retail discount industries. Prior to Gallagher, Doug owned and operated his own affinity marking agency which was sold to Gallagher 2014. Prior to that he had a 20 year career with Trans National Group Services where he successfully launched a number of affinity member benefit businesses including Trans National Communications which achieved #12 on the INC 500 list. He is a graduate of Boston College.`,
    },
    {
      title: `Sherry Lansing`,
      description: `Sherry is the former CEO of Paramount Pictures, and when president of production at 20th Century Fox was the first woman to head a Hollywood studio. Lansing has approached her post-Hollywood life with the same enthusiasm and creativity that characterized her earlier career. "I left Paramount at the ripe young age of sixty," notes Lansing. "A generation ago, that would have been retirement age. But my generation has more energy, more drive, and a greater life expectancy than any group of retirees before us. We are going to be here for two decades or more past 'retirement' age and we want to do something relevant in the so-called third act of our lives."`,
    },
    {
      title: `Jocelyn Talbot`,
      description: `Jocelyn served as VP, Sales at RetirementJobs.com for more than 10 years. She has spent more than 25 years in sales management, launching and growing successful sales and customer service organizations at leading companies such as Monster.com, 3M Company, and Wang Laboratories. During her ten year tenure as Sr. Vice President of Sales at Monster.com, Talbot was the driving force behind the unprecedented growth of the Inside Sales and Government Solutions Divisions. Talbot is a frequent speaker at Business Development Forums and American Telemarketing Management Conferences.`,
    },
    {
      title: `Peter Weddle`,
      description: `Peter Weddle has authored or edited over two dozen books and been a columnist for The Wall Street Journal, National Business Employment Weekly and CNN.com. He is also the CEO of WEDDLE's Research & Publications which specializes in employment and workforce issues and the Managing Director of the International Association of Employment Web Sites, the trade organization for the global online employment services industry. An Airborne Ranger, he is a graduate of the United States Military Academy at West Point. He has attended Oxford University and holds advanced degrees from Middlebury College and Harvard University.`,
    },
  ],
  footerData: [
    `The Corporate Advisory Board meets with management periodically but does not have legal responsibility for operations. The Company thanks the members of its Board of Advisors who so generously give of their time.`,
  ],
};

export const speakingEngagementsData = {
  title: `Speaking Engagements`,
  description: [
    `Tim Driver represents RetirementJobs.com, Mature Caregivers and Age Friendly Advisor as a keynote speaker and/or panelist on topics involving aging, healthcare, retirement income, and work in retirement.`,
  ],
  subtitle: `Past Engagements`,
  basicData: [
    {
      title: `Harvard Business School Healthcare Conference`,
      expandedDescription: [
        `Caring for an Aging Population`,
        `Cambridge, MA`,
        `Panelist`,
      ],
    },
    {
      title: `Future Agenda`,
      expandedDescription: [
        `Exploring the Potential for More Patient Centric Data to Transform Healthcare`,
        `Hosted by Philips Healthcare`,
        `Boston, MA`,
        `Panelist/Participant`,
      ],
    },
    {
      title: `Caregiving Global Summit`,
      expandedDescription: [`Age Without Borders`, `Panelist (Online)`],
    },
    {
      title: `The Future of Retirement: How and When Will People Retire?`,
      expandedDescription: [
        `Actex Learning / Society of Actuaries`,
        `Speaker (Webinar)`,
      ],
    },
    {
      title: `Massachusetts Governor Baker’s Council to Address Aging`,
      expandedDescription: [
        `Economic Security and the Wellbeing of Older Adults`,
        `Boston, MA`,
        `Prepared Remarks and Expert Panel`,
      ],
    },
    {
      title: `Seniors Strong Summit`,
      expandedDescription: [
        `Mystic Geriatrics Institute`,
        `Mystic, CT`,
        `Keynote Address`,
      ],
    },
    {
      title: `Living to 100 Symposium`,
      expandedDescription: [`Society of Actuaries`, `Orlando, FL`, `Speaker`],
    },
    {
      title: `Life Tomorrow? There's an App for That!`,
      expandedDescription: [`MIT Media Lab`, `Cambridge, MA`, `Speaker`],
    },
  ],
};

export const testimonialsData = {
  title: `Testimonials`,
  description: [],
  testimonialsItems: [
    {
      title: `U.S. Senate Aging Committee`,
      expandedDescription: [
        {
          description: `"Companies like RetirementJobs.com are filling a void by helping older workers keep their skills and expertise on the job. If these companies continue to adapt to accommodate the aging workforce and we pass the right legislation to help them succeed, we'll keep our economy growing well into the future."`,
          author: `Senator Herb Kohl, Chair, Senate Aging Committee.`
        }
      ]
    },
    {
      title: `Press and Analysts`,
      expandedDescription: [
        {
          description: `"A job seeker knows that employers posting jobs on this site will be open to talking to them even if--or perhaps especially if--they have a few more years of experience than is usually asked for. That makes this site not just a good resource, but a great confidence builder, too."`,
          author: `Rafe Needleman, veteran journalist at CNET.`
        },
        {
          description: `"RetirementJobs.com is a resource that is clearly in the right place at the right time."`,
          author: `Michael Smyer, Director of the Center for Aging and Work/Workplace Flexibility at Boston College.`
        },
        {
          description: `"Our missions very much align. RetirementJobs.com is focused not only on the mature workforce - but in helping employers to improve their policies and practices to meet the needs of older workers."`,
          author: `Deborah Russell, Workforce Issues, AARP.`
        },
        {
          description: `"Our missions very much align. RetirementJobs.com is focused not only on the mature workforce - but in helping employers to improve their policies and practices to meet the needs of older workers."`,
          author: `Deborah Russell, Workforce Issues, AARP.`
        }
      ],
    },
    {
      title: `Employers`,
      expandedDescription: [
        {
          description: `"RetirementJobs.com's certification gives RHI credibility with candidates. We only use RJC and AARP for this market. They have the highest customer service. We ask them to include us in all the press they get."`,
          author: `Camille Grabowski, Robert Half International.`
        },
        {
          description: `Federated Department Stores Company "There was nothing but unanimity from our divisions after announcing that we would join RetirementJobs.com."`,
          author: `Tim Plunkett, Division Vice President, Macy's.`
        },
        {
          description: `"Your site is really a great management tool and it is an easy way to review and add notes and organize groups... The candidates coming in are also highly qualified with rich life experiences that fit our job profile... I find that the candidates applying are adept at the computer, well educated and have a rich profile that matches our coaching needs. Thanks again for providing such a great resource."`,
          author: `Deb Sundal, Director of Program Services, United Healthcare.`
        },
        {
          description: `"Your site worked fantastic! Just wanted to thank you for your help in finding quality people for the positions I had open. I will use your site for my future needs."`,
          author: `Ronald W. Scott, L.J.Zucca Inc.`
        },
      ],
    },
    {
      title: `Job Seekers`,
      expandedDescription: [
        {
          description: `"About a month ago I visited your site and noted a local Fortune 500 Company looking for retiree part-time employees. I applied using you post resume function and I begin work this Tuesday. Your site is the real deal."`,
          author: `Name withheld for privacy.`
        },
        {
          description: `"I remember thinking that it would be the same as those other career/resume websites. Believe it or not, I was contacted within two business days with a list of employers looking for seasonal help. I was asked on an interview the same day I applied, I went for an interview and three days later, I was offered a part-time seasonal position with a major department store; and doing what I enjoy. When filling out the application for employment, in the section for how did you find out about us...I was proud to write I found out about the job from retirementjobs.com via AARP. I have enjoyed the new job and have been receiving many compliments from my customers. Thanks for brightening up my retirement future."`,
          author: `Name withheld for privacy.`
        },
        {
          description: `"You are a Godsend - what a super idea -- someone catering to those of us who have early retired but don't really want to retire totally. Brilliant concept! I have recently retired from education after a rewarding 36 years but I am, by no means, ready to stop working! I want to give back but I also want to be paid for my knowledge and skills. I am passing your website on to all my colleagues who have recently retired from education -- we are a growing number -- as you well know (and most of us are under 60 and very much wanting to continue in the work force)! Thanks so much for providing us with a resource to do just that. It is great to feel valued."`,
          author: `Name withheld for privacy.`
        },
      ],
    },

  ]
};

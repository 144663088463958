
import React, { useEffect } from 'react'
import './InterviewPage.scss'
import SectionNavigation from 'pages/AboutUsSection/_components/SectionNavigation/SectionNavigation'
import TipWithButton from 'components/TipWithButton/TipWithButton'
import { scrollToTop } from 'services/helpers'
import { useLocation } from 'react-router-dom'

const InterviewPage = () => {
    const location = useLocation()
    useEffect(() => {
        scrollToTop()
    },[location])
  return (
    <div className='interviewPage'>
        <div className='interviewPage_content'>
            <div className='interviewPage_leftrail'>
                <div className='interviewPage_header'>
                    <h2>3-Minute Pre-Interview</h2>
                    <p>This 3-minute pre-interview is used to gauge initial interest and qualifications for some of the frequently posted positions on RetirementJobs.com.</p>
                </div>

                <div className='interviewPage_form'>
                    <iframe title='3min' src='https://survey.alchemer.com/s3/7127508/47fdf68d6116' height={930}></iframe>
                </div>

            </div>
            <div className='interviewPage_rightrail'>
                <SectionNavigation/>
                <TipWithButton
                    title="Contact Us"
                    description={
                    <p>
                        Age-Friendly Institute <br /> 204 2<sup>nd</sup> Avenue <br />{" "}
                        support@agefriendly.org
                    </p>
                    }
                    url="/about/contact-us"
                    button="Contact us"
                />
            </div>
        </div>
    </div>
  )
}

export default InterviewPage
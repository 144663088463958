/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import CommonLink from 'components/CommonLink/CommonLink';
import { useLocation } from 'react-router-dom';

const RenderFooterLinks = ({ data, linksType, jobsByState, state }) => {
  const [columnSizes, setColumnSize] = useState(linksType === 'category' ? [4, 4, 4, 3, 3] : [10, 10, 10, 10, 10]);
  const [result, setResult] = useState([]);
  const location = useLocation()

  const windowWidthRef = useRef(window.innerWidth);

  const resizeFunction = () => {
    if(windowWidthRef.current < 450) {
      setColumnSize(linksType === 'category' ? [18] : [25, 25])
    } else if(windowWidthRef.current < 695) {
      setColumnSize(linksType === 'category' ? [9, 9] : [25, 25])
    } else if(windowWidthRef.current < 1080 || jobsByState) {
      setColumnSize(linksType === 'category' ? [6, 6, 6] : [17, 17, 17])
    } else {
      setColumnSize(linksType === 'category' ? [4, 4, 4, 3, 3] : [10, 10, 10, 10, 10])
    }
  }

  const handleResize = () => {
    const newWidth = window.innerWidth;
    windowWidthRef.current = newWidth;
    resizeFunction()
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    resizeFunction()
  }, [windowWidthRef.current]);

  function replaceCityStateZip(link, replacement) {
    const regex = /city_state_zip=/g;
    const modifiedUrl = link?.replace(regex, `city_state_zip=${replacement}`);
    return modifiedUrl;
  }

  function categoryLink(link, state) {
    if(location.pathname.startsWith('/jobs-for-seniors')) {
      return replaceCityStateZip(link, state)
    } else {
      return link
    }
  }

  useEffect(() => {
    const subarrays = [];

    let index = 0;
    columnSizes.forEach(columnSize => {
      const subarray = data.slice(index, index + columnSize);
      subarrays.push(subarray);
      index += columnSize;
    });

    setResult(subarrays);
  }, [data, columnSizes]);

  const links = result.map((subarray, index) => (
    <div className='linksColumn' key={index}>
      {subarray.map(({ abbrev, name, link }) => {
        const modifiedLink = name === 'Louisiana'
          ? 'louisiana'
          : name
            .replace(/\//g, '')
            .replace(/\s+/g, '-')
            .toLowerCase()

          return (
            <span key={abbrev}>
              <CommonLink 
                path={
                  linksType === 'state' 
                  ? `/jobs-for-seniors/${modifiedLink}` 
                  : categoryLink(link, state)
                } 
                text={name}
                state={name}
              />
            </span>
          )
      })}
    </div>
  ))



  return (
    <>
      {links}
    </>
  );
};

export default RenderFooterLinks;
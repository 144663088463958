import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import SEO from 'components/SEOComponent/SEO'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { scrollToTop } from 'services/helpers'
import StateJobInfo from './_components/StateJobInfo/StateJobInfo'

const JobsInState = () => {

    const location = useLocation()
    useEffect(() => {
        scrollToTop()
    }, [location])

    return (
        <ContentWrapper>
            <SEO title={`Jobs for Seniors in ${location.state} | RetirementJobs.com`} description={`Find jobs for seniors in ${location.state} by city. The best source for workers over 50 looking for jobs in ${location.state}. View all openings by city.`} keywords={''}/>
            <StateJobInfo state={location.state}/>
        </ContentWrapper>
    )
}

export default JobsInState
import React, { useEffect, useState, useMemo } from "react";

import Pagination from "components/Pagination/Pagination";
import { scrollToTop } from "services/helpers";
import { useGetInTheNewsArticlesQuery } from "services/api/inTheNewsApi";

import "./InTheNewsComponent.scss";
import Preloader from "components/Preloader/Preloader";

const InTheNewsItem = ({ image, title, link, description}) => {
  return (
    <div className="newsItem">
      <img
        className="image"
        src={image}
        alt="itemImg"
      />
        <a className="headingLink" href={link} target="_blanc">
          {title}
        </a>
        <p className="content">
          {description}
        </p>
    </div>
  )
}

const InTheNews = () => {
  const [page, setPage] = useState(1)
  const { data } = useGetInTheNewsArticlesQuery({page})

  useEffect(() => {
    scrollToTop()
  }, [])

  const renderList = (data) => (
    data?.news_items.map(item => (<div key={item.id}><InTheNewsItem image={item.image} link={item.link} title={item.name} description={item.description}/></div>))
  )
  const memoizedList = useMemo(() => renderList(data), [data])

  return (
    <>
      <div className="inTheNewsComponent">
        <h2>In the News</h2>
        <div className="inTheNewsComponent_contentBlock">
          <div className="inTheNewsComponent_list">
            {data 
              ? memoizedList
              : <Preloader size={'L'}/>
            }
            { data?.total_pages > 1 && 
              <Pagination
                onPageChange={setPage}
                totalPages={data.total_pages}
                currentPage={data.current_page}
              />
            }
          </div>

        </div>
      </div>
    </>
  );
};

export default InTheNews;

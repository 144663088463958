import React from 'react'
import './StateJobInfo.scss'

import { statesData } from 'constants/jobsByStateContent'
import AFResources from '../AFResources/AFResources'
import MoreResources from '../MoreResources/MoreResources'
import JustForFun from '../JustForFun/JustForFun'
import JobByCategories from 'components/JobByCategories/JobByCategories'
import CitiesList from '../CitiesList/CitiesList'

const StateJobInfo = ({state}) => {
    const selectedState = statesData.find(item => item.state === state)
    return (
        <section className='stateJobInfo'>
            <div className='stateJobInfo_content'>
                <div className='stateJobInfo_leftrail'>
                    <div className='stateJobInfo_header'>
                        <h2>
                            {`Jobs for Seniors in ${state}`}
                        </h2>
                    </div>
                    <div className='stateJobInfo_subtitle'>
                        <h4>{`Current ${state} Employment Statistics`}</h4>
                        <p>
                            {selectedState.currentStatistics}
                        </p>
                    </div>
                    <div className='stateJobInfo_cities'>
                        <div className='stateJobInfo_cities__header'>
                            <h3>{`Jobs for Seniors in ${state} by City`}</h3>
                            <p>
                                Select your city or the closest city to you in order to view the jobs for seniors in that location
                            </p>
                        </div>
                        <CitiesList state={state}/>
                    </div>

                    <JobByCategories state={state}/>

                </div>
                <div className='stateJobInfo_rightrail'>
                    <AFResources state={state}/>
                    <MoreResources data={selectedState} />
                    <JustForFun data={selectedState.justForFun} />
                </div>
            </div>
        </section>
    )
}

export default StateJobInfo
import React from 'react'
import SearchSection from './_components/SearchSection/SearchSection'
import JESection from './_components/JobsnEmpoyersSection/JESection'
import JobSeekersSection from './_components/JobSeekersSection/JobSeekersSection'
import AgeBiasSection from './_components/AgeBiasSection/AgeBiasSection'
import SEO from 'components/SEOComponent/SEO'
import { useGetMetaDataQuery } from 'services/api/metaDataApi'

const HomePage = () => {
  const { data } = useGetMetaDataQuery('home')

  if(!data) return null
  return (
    <>    
      <SEO title={'Retirement Jobs'} description={'Retirement Jobs'} keywords={'Retirement Jobs'}/>
      <SearchSection/>
      <AgeBiasSection/>
      <JESection/>
      <JobSeekersSection/>
    </>
  )
}

export default HomePage
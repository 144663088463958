import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import React from 'react'
import CareerServiceList from './_components/CareerServiceList/CareerServiceList'
import SEO from 'components/SEOComponent/SEO'
import { useGetMetaDataQuery } from 'services/api/metaDataApi'

const CareerService = () => {
  const { data } = useGetMetaDataQuery('career_services')

  return (
    <>
      <SEO title={data?.data_attributes.meta_title} description={data?.data_attributes.meta_description} keywords={data?.data_attributes.meta_keywords}/>

      <ContentWrapper>
        <CareerServiceList/>
      </ContentWrapper>
    </>
  )
}

export default CareerService
import React, { useState } from 'react'
import './SearchSection.scss'

import Input from 'components/Input/Input'
import CommonButton from 'components/CommonButton/CommonButton'
import TrendingTopics from '../TrendingTopics/TrendingTopics'
import AutocompleteInput from 'components/AutocompleteInput/AutocompleteInput'
import { useGetSearchResultsQuery } from 'services/api/searchApi'

const SearchSection = () => {
  const [locations, setLocations] = useState('')
  const [keywords, setKeywords] = useState('')
  const [selectedOption, setSelectedOption] = useState()
  
  const { data, error } = useGetSearchResultsQuery({
      locations,
      enabled: false,
      skip: keywords?.length < 1,
  })

  const generateLink = (data) => {
    const city = data.city?.replace(/\s/g, '+');
    const state = data.state?.replace(/\s/g, '+');
    return `https://search.retirementjobs.com/jobs?country=United+States&state=${state}&city=${city}&zip=${data.zip}&latitude=${data.latitude}&longitude=${data.longitude}&keywords=${keywords}`
  }


  const handleOnSearch = () => {
    if(!selectedOption && !keywords.length) {
      window.location.href = 'https://search.retirementjobs.com/jobs?country=United+States&state=&city=&zip=&latitude=&longitude=&keywords=&city_state_zip=&locale=en&page=1&sort=relevance'
    } else {
      let url;
      const selectedData = data?.locations.data.find(item => item.value === selectedOption[0]?.label);
      selectedData 
      ? url = generateLink(selectedData?.data) 
      : url = `https://search.retirementjobs.com/jobs?country=United+States&state=&city=&zip=&latitude=&longitude=&keywords=${keywords}&city_state_zip=&locale=en&page=1&sort=relevance`
      window.location.href = url
    }
  }

  const handleKeywords = (target) => {
    setKeywords(target.value)
  }
  
  return (
    <div className="searchSection">
      <div className="searchFields_bg searchFields_bg-image"></div>
      <div className="searchSeal_bg"></div>

      <div className="searchSection_container">
        <div className="searchFields searchFields_bg-image">
          <div className='searchSection_main'>
              <h1 className='searchSection_header'>Discover the Best Jobs for People over 50</h1>
              <div className='searchSection_inputs'>
                <AutocompleteInput addClass={'location_input'} label='Location' onChange={setLocations} options={!error ? data?.locations.data : []} setSelectedOption={setSelectedOption} selectedOption={selectedOption}/>
                <Input type='text' label='Keyword(optional)' placeholder='Example: sales' addClass='keyword_input' onChange={handleKeywords} addIcon='keyword_input'/>
                <CommonButton text='Search' addColor='orange' onClick={handleOnSearch}/>
              </div>
          </div>
        </div>

        <div className="searchSeal">
          <TrendingTopics/>
        </div>
      </div>
    </div>
  )
}

export default SearchSection

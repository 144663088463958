import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom'

import IndividualArticle from './_components/IndividualArticle/IndividualArticle'
import { useGetCareerAdviceArticleItemQuery } from 'services/api/careerAdviceApi'
import SEO from 'components/SEOComponent/SEO'
import { scrollToTop } from 'services/helpers'

const CareerArticlePage = () => {
  const location = useLocation();
  const articleId = location.pathname.split('/')[2]; 
  const { data } = useGetCareerAdviceArticleItemQuery(articleId)
  useEffect(() => {
    scrollToTop()
  },[data])
  
  return (
    <ContentWrapper>
        <SEO title={data?.data_attributes.meta_title} description={data?.data_attributes.meta_description} keywords={data?.data_attributes.meta_keywords}/>
        <IndividualArticle data={data}/>
    </ContentWrapper>
  )
}

export default CareerArticlePage